
export const fetchBaseModalData = (lang, type, callback, id = null) => {
    fetch("/js/" + lang + "/translation.js")
    .then(res => res.json())
    .then(res => {
        let langTxt = res;

        if (type != null) {
            let url = "/" + lang + "/admin/list/" + type;

            if (type == "courses") {
                url = "/" + lang + "/courses/asJson";
            }
            else if (type == "groups") {
                url = "/" + lang + "/courses/" + id + "/groups/asJson";
            }
            else if (type == "reasons") {
                url =  "/" + lang + "/reasons/list/json";
            }

            fetch(url)
            .then(res => res.json())
            .then(res => {
                callback(langTxt, res);
            });
        }
        else {
            callback(langTxt);
        }
    });
}