import {Tooltip} from 'bootstrap';

window.Modal = require('./modal');

window.FormComponent = require('./form-component');

window.CalendarView = require('./calendar-view');
window.CalendarUpdate = require('./calendar-update');
window.Schedule = require('./schedule.js');
window.LongAbsence = require('./long-absence.js');
window.Utils = require('./utils.js');
window.Algo = require('./algo.js');
window.Predictions = require('./predictions.js');
window.PredictionsGeneration = require('./predictions-generation.js');
window.PredictionsReport = require('./predictions-report.js');
window.ItemList = require('./item-list.js');
window.SurveillanceSchedule = require('./surveillance-schedule.js');
window.SurveillanceAbsence = require('./surveillance-absence.js');
window.YearCalendar = require('./year-calendar.js');
window.SelectList = require('./select-list.js');
window.SelectionList = require("./selection-list.js");

window.addEventListener("load", () => {
    document.querySelectorAll(".date-picker").forEach(node => {
        let timeoutId = null;
        node.onblur= function () {this.closest('form').submit();}
        node.onchange= function () {
            clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                this.closest('form').submit();
            }, 1000);
        }
    })

})

window.showChangeReasonModal = (lang, absenceId, currentReasonId) => {
    Utils.fetchBaseModalData(lang, "reasons", (langTxt, reasons) => {
        let div = document.createElement("div");
        let select = document.createElement("select");
        select.classList.add("form-select", "reason-id-select");

        reasons.forEach(reason => {
            let option = document.createElement("option");
            option.value = reason.id;
            option.innerText = reason["name_" + lang];
            select.append(option);
        })

        select.value = currentReasonId;

        div.append(select);

        window.Modal.show(langTxt.gen["Choose Reason"], div, () => {
            const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            let val = select.value;
            div.innerText = langTxt.gen["Please Wait"] + "...";

            let formData = new FormData();
            formData.append("absence_id", absenceId);
            formData.append("reason_id", val);

            fetch("/" + lang + "/approbations/change-absence-reason", {
                method : "POST",
                body : formData,
                headers: new Headers({
                    "X-CSRF-TOKEN": token,
                })
            })
            .then(res => res.json())
            .then(res => {
                window.location.reload();
            })
        });
    })
}

window.showTeacherAbsenceModal = lang => {
    Utils.fetchBaseModalData(lang, "teachers", (langTxt, teachers) => {
        let div = document.createElement("div");
        let select = document.createElement("select");
        select.classList.add("form-select", "teacher-id-select");

        teachers.forEach(teacher => {
            let option = document.createElement("option");
            option.value = teacher.id
            option.innerText = teacher.last_name + ", " + teacher.first_name;
            select.append(option);
        })

        div.append(select);

        window.Modal.show(langTxt.gen["Choose Teacher"], div.outerHTML, () => {
            window.location.href="/" + lang + "/absences/" + document.querySelector(".teacher-id-select").value + "/new";
        });
    })
}

window.generatePayPeriods = lang => {
    Utils.fetchBaseModalData(lang, null, langTxt => {
        let div = document.createElement("div");

        let input = document.createElement("input");
        input.type = "date";
        input.classList.add("period-start-date", "form-control");
        input.placeholder = langTxt.gen["Start Date"];

        div.append(input);

        input = document.createElement("input");
        input.type = "number";
        input.min = "0";
        input.max = "30";
        input.classList.add("period-repetitions", "form-control", "mt-2");
        input.placeholder = langTxt.gen["Repetitions"];

        div.append(input);

        window.Modal.show(langTxt.gen["Attention"], div, () => {
            let date = div.querySelector(".period-start-date").value;
            let repetitions = div.querySelector(".period-repetitions").value;

            if (repetitions > 0 && date.length > 0) {
                const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
                document.querySelector(".btn-modal-main").style.display = "none";
                div.innerText = langTxt.gen["Please Wait"] + "...";

                let formData = new FormData();
                formData.append("date_start", date);
                formData.append("repetitions", repetitions);

                fetch("/" + lang + "/admin/pay/generate", {
                    method : "POST",
                    body : formData,
                    headers: new Headers({
                        "X-CSRF-TOKEN": token,
                    })
                })
                .then(res => res.json())
                .then(res => {
                    window.location.reload();
                })
            }
        });
    })
}

window.sendLookupEmailForTodaysAbsences =  (lang) => {
    Utils.fetchBaseModalData(lang, null, langTxt => {
        let div = document.createElement("div");
        div.innerText = langTxt.gen["Lookup Today's Absences"];

        window.Modal.show(langTxt.gen["Attention"], div, () => {
            const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            document.querySelector(".btn-modal-main").style.display = "none";
            div.innerText = langTxt.gen["Please Wait"] + "...";

            fetch("/" + lang + "/admin/absences/send-manual-lookup-for-today-absences", {
                method : "POST",
                headers: new Headers({
                    "X-CSRF-TOKEN": token,
                })
            })
            .then(res => res.json())
            .then(res => {
                div.innerHTML = res;
            })
        });
    })
};

window.sendLookupEmail =  (lang, absenceId, medium) => {
    Utils.fetchBaseModalData(lang, null, langTxt => {
        let div = document.createElement("div");
        div.innerText = langTxt.gen["Confirm Send Lookup Email"];

        if (medium == "SMS") {
            div.innerText = langTxt.gen["Confirm Send Lookup SMS"];
        }

        window.Modal.show(langTxt.gen["Attention"], div, () => {
            const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            document.querySelector(".btn-modal-main").style.display = "none";
            div.innerText = langTxt.gen["Please Wait"] + "...";

            let formData = new FormData();
            formData.append("medium", medium);

            fetch("/" + lang + "/admin/absences/" + absenceId + "/send-manual-lookup", {
                method : "POST",
                body : formData,
                headers: new Headers({
                    "X-CSRF-TOKEN": token,
                })
            })
            .then(res => res.json())
            .then(res => {
                div.innerHTML = res;
            })
        });
    })
};

window.notifySubstitute = (lang, absenceId) => {
    Utils.fetchBaseModalData(lang, null, langTxt => {
        let div = document.createElement("div");
        let area = document.createElement("textarea");
        area.classList.add("form-control", "note-area");
        area.style.minHeight = "150px";

        div.append(area);

        window.Modal.show(langTxt.gen["Note To Send"], div, () => {
            if (area.value.trim().length > 0) {
                const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
                let formData = new FormData();
                formData.append("note", area.value);

                document.querySelector(".btn-modal-main").style.display = "none";
                div.innerText = langTxt.gen["Please Wait"] + "...";

                fetch("/" + lang + "/admin/absences/" + absenceId + "/notify", {
                    method : "POST",
                    body : formData,
                    headers: new Headers({
                        "X-CSRF-TOKEN": token,
                    })
                })
                .then(res => res.json())
                .then(res => {
                    div.innerHTML = res;
                    document.querySelector(".btn-modal-main").style.display = "none";
                })
            }
        });
    });
};

window.notifyStudents = (lang, absenceId) => {
    Utils.fetchBaseModalData(lang, null, langTxt => {
        let div = document.createElement("div");
        let area = document.createElement("textarea");
        area.classList.add("form-control", "note-area");
        area.style.minHeight = "150px";

        div.append(area);

        window.Modal.show(langTxt.gen["Note To Send To Students"], div, () => {
            if (area.value.trim().length > 0) {
                const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
                let formData = new FormData();
                formData.append("note", area.value);

                document.querySelector(".btn-modal-main").style.display = "none";
                div.innerText = langTxt.gen["Please Wait"] + "...";

                fetch("/" + lang + "/admin/absences/" + absenceId + "/notify-students", {
                    method : "POST",
                    body : formData,
                    headers: new Headers({
                        "X-CSRF-TOKEN": token,
                    })
                })
                .then(res => res.json())
                .then(res => {
                    div.innerHTML = res;
                    document.querySelector(".btn-modal-main").style.display = "none";
                })
            }
        });
    });
};

window.showSubstituteAbsenceModal = (lang, absenceId, currentSubstitute = null) => {
    Utils.fetchBaseModalData(lang, null, langTxt => {
        fetch("/" + lang + "/admin/absences/" + absenceId + "/get-substitutes", {
            method : "GET",
        })
        .then(res => res.json())
        .then(data => {
            let div = document.createElement("div");
            let select = document.createElement("select");
            select.classList.add("form-select", "teacher-id-select");

            let option = document.createElement("option");
            option.value = "0";
            option.innerText = "- " + langTxt.gen["Automatic Assignation"] + " -";
            select.append(option);

            let currentSubstituteName = null;

            data.teachers.forEach(teacher => {
                let option = document.createElement("option");
                option.value = teacher.id
                option.innerText = teacher.last_name + ", " + teacher.first_name;

                if (currentSubstitute != null && currentSubstitute != "") {
                    if (currentSubstitute == teacher.id) {
                        option.selected = true;
                        currentSubstituteName = option.innerText;
                    }
                }

                select.append(option);
            })

            div.append(select);

            let excludeInput = null;
            if (currentSubstitute != null && currentSubstitute != "" && currentSubstituteName != null) {
                let excludeDiv = document.createElement("div");
                excludeDiv.classList.add("border", "mt-2");
                excludeInput = document.createElement("input");
                excludeInput.classList.add("ms-2", "me-2");
                excludeInput.type = "checkbox";
                excludeInput.value = currentSubstitute;
                let excludeLabel = document.createElement("label");
                excludeLabel.append(excludeInput, langTxt.gen["Exclude from Automatic Assignations"] + " : " + currentSubstituteName);
                excludeDiv.append(excludeLabel);
                div.append(excludeDiv);
            }

            if (data.exclusions.length > 0) {
                let exclusionList = document.createElement("div");
                exclusionList.style.fontSize = "0.8em";
                exclusionList.classList.add("card", "p-1");
                exclusionList.append(langTxt.gen["Current exclusion list"] + " : ");

                let exclusionNameContainer = document.createElement("div");
                exclusionList.append(exclusionNameContainer);

                data.exclusions.forEach(exc => {
                    let excButton = document.createElement("button");
                    excButton.classList.add("d-inline", "btn", "btn-sm", "btn-outline-primary", "m-1");
                    excButton.innerHTML = exc.name;
                    excButton.onclick = () => {
                        const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
                        let formData = new FormData();
                        formData.append("id", exc.id);

                        fetch("/" + lang + "/admin/absences/" + absenceId + "/remove-from-exclusion-list", {
                            method : "POST",
                            body : formData,
                            headers: new Headers({
                                "X-CSRF-TOKEN": token,
                            })
                        })
                        .then(res => res.json())
                        .then(res => {
                            window.location.reload();
                        })
                    }

                    exclusionNameContainer.append(excButton);
                });

                div.append(exclusionList);
            }

            window.Modal.show(langTxt.gen["Choose Substitute"], div, () => {
                const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
                let formData = new FormData();

                if (currentSubstituteName != null && excludeInput.checked) {
                    formData.append("exclude-teacher-id", currentSubstitute);
                }

                formData.append("id", document.querySelector(".teacher-id-select").value);

                fetch("/" + lang + "/admin/absences/" + absenceId + "/set-substitute", {
                    method : "POST",
                    body : formData,
                    headers: new Headers({
                        "X-CSRF-TOKEN": token,
                    })
                })
                .then(res => res.json())
                .then(res => {
                    window.location.reload();
                })
            });
        })
    });
}

window.confirmExportFinanceReport = (lang, allConfirmed) => {
    if (allConfirmed) {
        exportReport("export-preview");
    }
    else {
        Utils.fetchBaseModalData(lang, null, langTxt => {
            let div = document.createElement("div");
            div.classList.add("alert", "alert-warning")
            div.innerHTML = langTxt.gen["All Absences Should Be Approved"];

            let modalView = window.Modal.show(langTxt.gen["Confirm"], div, () => {
                exportReport("export-preview");
                modalView.hide();
            });
        })
    }
}

window.askGroup = (lang, courseId) => {
    Utils.fetchBaseModalData(lang, null, langTxt => {
        let div = document.createElement("div");
        let input = document.createElement("input");
        input.classList.add("new-group-id", "form-control");
        input.placeholder = langTxt.gen["Group Number"];
        div.append(input);

        window.Modal.show(langTxt.gen["Group"], div.outerHTML, () => {
            if (document.querySelector(".new-group-id").value.trim().length > 0 && !isNaN(document.querySelector(".new-group-id").value)) {
                window.location.href="/" + lang + "/courses/" + courseId + "/groups/" + document.querySelector(".new-group-id").value;
            }
        });
    })
}

window.createYearCalendarVersion = (lang, year, currentVersionId) => {
    Utils.fetchBaseModalData(lang, null, langTxt => {
        let div = document.createElement("div");
        let input = document.createElement("input");
        input.classList.add("name-id", "form-control");
        input.placeholder = langTxt.gen["Name"];
        div.append(input);

        let label = document.createElement("label");
        label.classList.add("user-select-none", "p-1", "border", "rounded", "mt-2", "d-flex", "align-items-center");
        let duplicateOption = document.createElement("input");
        duplicateOption.style.marginRight = "10px";
        duplicateOption.type = "checkbox";
        label.append(duplicateOption, langTxt.gen["Duplicate from current calendar"]);
        div.append(label);

        window.Modal.show(langTxt.gen["Version Name"], div, () => {
            if (document.querySelector(".name-id").value.trim().length > 0) {
                const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

                let formData = new FormData();
                formData.append("current_version_id", currentVersionId);
                formData.append("must_duplicate", duplicateOption.checked ? "1" : "0");
                formData.append("name", document.querySelector(".name-id").value.trim());

                fetch("/" + lang + "/admin/year-calendar/" + year + "/create-version", {
                    method : "POST",
                    body : formData,
                    headers: new Headers({
                        "X-CSRF-TOKEN": token,
                    })
                })
                .then(res => res.json())
                .then(versionId => {
                    window.location.href = "/" + lang + "/admin/year-calendar?y=" + year + "&v=" + versionId;
                })
            }
        });
    })
}

window.renameYearCalendarVersion = (lang, year, versionId, currentName) => {
    Utils.fetchBaseModalData(lang, null, langTxt => {
        let div = document.createElement("div");
        let input = document.createElement("input");
        input.classList.add("name-id", "form-control");
        input.placeholder = langTxt.gen["Name"];
        input.value = currentName;
        div.append(input);

        window.Modal.show(langTxt.gen["Version Name"], div, () => {
            if (document.querySelector(".name-id").value.trim().length > 0) {
                const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

                let formData = new FormData();
                formData.append("name", document.querySelector(".name-id").value.trim());

                fetch("/" + lang + "/admin/year-calendar/" + year + "/" + versionId + "/rename", {
                    method : "POST",
                    body : formData,
                    headers: new Headers({
                        "X-CSRF-TOKEN": token,
                    })
                })
                .then(res => res.json())
                .then(versionId => {
                    window.location.href = "/" + lang + "/admin/year-calendar?y=" + year + "&v=" + versionId;
                })
            }
        });
    })
}


window.changeGroupNumberModal = (lang, courseId, groupNumber) => {
    Utils.fetchBaseModalData(lang, null, langTxt => {
        let div = document.createElement("div");
        let input = document.createElement("input");
        input.classList.add("new-group-id", "form-control");
        input.placeholder = langTxt.gen["Group Number"];
        input.value = groupNumber;

        let p = document.createElement("p");
        p.classList.add("alert", "alert-secondary");
        p.style.fontSize = "0.9rem";
        p.innerHTML = langTxt.gen["Semester Group Format"];
        div.append(p);

        div.append(input);

        window.Modal.show(langTxt.gen["Group"], div, () => {
            if (document.querySelector(".new-group-id").value.trim().length > 0) {
                let newGroupName = document.querySelector(".new-group-id").value;
                const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
                let formData = new FormData();
                formData.append("group_name", newGroupName);

                fetch("/" + lang + "/courses/" + courseId + "/groups/" + groupNumber + "/change-name", {
                    method : "POST",
                    body : formData,
                    headers: new Headers({
                        "X-CSRF-TOKEN": token,
                    })
                })
                .then(res => res.json())
                .then(res => {
                    window.location.href = "/" + lang + "/courses/" + courseId + "/groups/" + newGroupName;
                })
            }
        });
    })
}

window.quietSubstitution = (lang, form) => {
    Utils.fetchBaseModalData(lang, null, langTxt => {
        let div = document.createElement("div");
        div.innerText = langTxt.gen["Stop Notifications"] + "?";

        window.Modal.show(langTxt.gen["Attention"], div, () => {
            form.submit();
        });
    })
}

window.becomeSubstitute = (lang, form) => {
    Utils.fetchBaseModalData(lang, null, langTxt => {
        let div = document.createElement("div");
        div.innerText = langTxt.gen["Become Substitute"] + "?";

        window.Modal.show(langTxt.gen["Attention"], div, () => {
            form.submit();
        });
    })
}

window.addEventListener("load", () => {
    let mainCheckbox = document.querySelector(".main-check");

    const updateBtnState = () => {
        let checkedIds = [];

        document.querySelectorAll(".list-check").forEach(check => {
            if (check.checked) {
                checkedIds.push(check.value);
            }
        })

        document.querySelectorAll("button.list-check-dependent").forEach(button => {
            button.disabled = checkedIds.length == 0;
        });
    }

    if (mainCheckbox != null) {
        mainCheckbox.addEventListener("change", () => {
            document.querySelectorAll(".list-check").forEach(check => {
                check.checked = mainCheckbox.checked;
            })

            updateBtnState();
        });


        document.querySelectorAll(".list-check").forEach(check => {
            check.addEventListener("change", updateBtnState);
        });

        updateBtnState();
    }

    let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    });

    let searchParams = new URLSearchParams(window.location.search)

    document.querySelectorAll("th").forEach(th => {
        if (th.getAttribute("data-sort") != null) {
            th.onclick = () => sortColumn(th.getAttribute("data-sort"));
            th.classList.add("text-primary", "cursor-pointer");

            if (searchParams.get("o") == th.getAttribute("data-sort")) {
                th.classList.add("text-decoration-underline");
            }
        }
    })
});


const sortColumn = (name) => {
    if (window.location.href.indexOf("?") == -1) {
        window.location.href = window.location.href + "?o=" + name;
    }
    else if (window.location.href.indexOf("o=") == -1) {
        window.location.href = window.location.href + "&o=" + name;
    }
    else {
        let searchParams = new URLSearchParams(window.location.search)

        if (searchParams.get("o") == name) {
            searchParams.delete("o");
        }
        else {
            searchParams.delete("o");
            searchParams.append("o", name);
        }

        window.location.href = "?" + searchParams.toString();
    }
}

window.exportReport = (pageName = "export") => {
    let searchParams = new URLSearchParams(window.location.search);
    window.location.href = location.pathname + "/" + pageName + "?" + searchParams.toString();
}

window.startAdminNotifications = (lang) => {
    let container = document.querySelector("#admin-notification-container");

    setTimeout(() => {
        Utils.fetchBaseModalData(lang, null, langTxt => {
            const refreshNotif = (minId = 0) => {
                fetch("/" + lang + "/admin/latest-notifications/" + minId, {
                    method : "GET",
                })
                .then(res => res.json())
                .then(res => {
                    res.forEach(notif => {
                        let div = document.createElement("div");
                        div.classList.add("alert", "alert-info", "position-relative", "pe-5");
                        let user = "<strong>" + notif.first_name + " " + notif.last_name  + "</strong>";
                        let detail = "n/a";

                        if (notif.notif_type == "NOTIF_ACCEPT_ASSIGN") {
                            detail = langTxt.gen["Has Accepted a Replacement for an Absence Today"];
                        }
                        else if (notif.notif_type == "NOTIF_NEW_ABSENCE_FOR_TODAY") {
                            detail = langTxt.gen["Has Created A New Absence For Today"];
                        }

                        div.innerHTML = user + " " + detail + notif.period + " (<strong>" + notif.absence_title + "</strong>)";

                        let timeDiv = document.createElement("div");
                        timeDiv.style.fontSize = "0.7rem";
                        timeDiv.classList.add("mt-2");
                        timeDiv.innerHTML = new Date(notif.created_at).toLocaleTimeString();
                        div.append(timeDiv);

                        let divBtn = document.createElement("button");
                        divBtn.innerHTML = "X";
                        divBtn.classList.add("btn", "btn-sm", "btn-outline-info", "position-absolute");
                        divBtn.style.right = "10px";
                        divBtn.style.top = "10px";
                        divBtn.onclick = () => {
                            const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

                            fetch("/" + lang + "/admin/latest-notifications/delete/" + notif.id, {
                                method : "POST",
                                headers: new Headers({
                                    "X-CSRF-TOKEN": token,
                                })
                            })
                            .then(res => res.json())
                            .then(res => {
                                div.remove();
                            })
                        }
                        div.append(divBtn);

                        container.prepend(div);
                        minId = notif.id;
                    });

                    setTimeout(() => refreshNotif(minId), 10000);
                })
            }

            refreshNotif();
        })
    }, 5000);
}

window.addStudentCourseGroup = (lang, id) => {
    Utils.fetchBaseModalData(lang, "courses", (langTxt, courses) => {
        let div = document.createElement("div");
        let select = document.createElement("select");
        select.classList.add("form-select", "course-id-select");

        let selectGroup = document.createElement("select");
        selectGroup.classList.add("form-select", "group-id-select", "mt-2");

        const groupChanged = courseId => {
            const addGrp = groupName => {
                let option = document.createElement("option");
                option.value = groupName;
                option.innerText = groupName;
                selectGroup.append(option);
            }
            Utils.fetchBaseModalData(lang, "groups", (langTxt, groups) => {
                selectGroup.innerHTML = "";

                groups.forEach(group => {
                    if (group.group.indexOf("-") == -1) {
                        addGrp(group.group);
                    }
                    else {
                        addGrp(group.group.split("-")[0]);
                        addGrp(group.group.split("-")[1]);
                    }
                })
            }, courseId);
        }


        courses.forEach(course => {
            let option = document.createElement("option");
            option.value = course.id
            option.innerText = course.code;
            select.append(option);
        })

        div.append(select);

        select.onchange = () => {
            groupChanged(select.value);
        }

        groupChanged(select.value);

        div.append(selectGroup);

        window.Modal.show(langTxt.gen["Choose Course and Group"], div, () => {
            const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            if (select.value.trim().length > 0 && selectGroup.value.trim().length > 0) {
                let formData = new FormData();
                formData.append("course_id", select.value);
                formData.append("group_name", selectGroup.value);

                fetch("/" + lang + "/students/" + id + "/add-course-group", {
                    method : "POST",
                    body : formData,
                    headers: new Headers({
                        "X-CSRF-TOKEN": token,
                    })
                })
                .then(res => res.json())
                .then(res => {
                    window.location.reload();
                })
            }
        });
    })
}

window.communicateWithStudents = lang => {
    Utils.fetchBaseModalData(lang, "courses", (langTxt, courses) => {
        let div = document.createElement("div");
        let select = document.createElement("select");
        select.classList.add("form-select", "course-id-select");

        let selectGroup = document.createElement("select");
        selectGroup.classList.add("form-select", "group-id-select", "mt-2");

        const groupChanged = courseId => {
            Utils.fetchBaseModalData(lang, "groups", (langTxt, groups) => {
                selectGroup.innerHTML = "";

                groups.forEach(group => {
                    let option = document.createElement("option");
                    option.value = group.group
                    option.innerText = group.group;
                    selectGroup.append(option);
                })
            }, courseId);
        }


        courses.forEach(course => {
            let option = document.createElement("option");
            option.value = course.id
            option.innerText = course.code;
            select.append(option);
        })

        div.append(select);

        select.onchange = () => {
            groupChanged(select.value);
        }

        groupChanged(select.value);

        div.append(selectGroup);

        window.Modal.show(langTxt.gen["Choose Course and Group"], div, () => {
            if (select.value.trim().length > 0 && selectGroup.value.trim().length > 0) {
                window.location.href = "/" + lang + "/courses/" + select.value + "/groups/" + selectGroup.value + "/communication";
            }
        });
    })
}

window.changeSemesterDate = lang => {
    Utils.fetchBaseModalData(lang, null, (langTxt, courses) => {
        let div = document.createElement("div");
        let input = document.createElement("input");
        input.type = "date";
        input.classList.add("form-control");

        div.append(input);

        window.Modal.show(langTxt.gen["Choose Second Semester Date"], div, () => {
            if (input.value != "") {
                const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

                let formData = new FormData();
                formData.append("start_date", input.value);

                fetch("/" + lang + "/admin/calendar/change-semester-date", {
                    method : "POST",
                    body : formData,
                    headers: new Headers({
                        "X-CSRF-TOKEN": token,
                    })
                })
                .then(res => res.json())
                .then(res => {
                    window.location.reload();
                })
            }
        });
    })
}

window.showAlert = text => {
    window.Modal.show("Attention", text);
}
