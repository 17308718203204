import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {show} from './modal';

export const load = (teacherId, nodeId, lang, entryId) => {
    ReactDOM.render(
		<React.StrictMode>
			<React.Fragment>
                <Form teacherId={teacherId} lang={lang} entryId={entryId} />
			</React.Fragment>
		</React.StrictMode>,
		document.getElementById(nodeId)
    )
}

function Form (props) {
    const [lang, updateLang] = useState(null);
    const [data, updateData] = useState(null);
    const [periods, updatePeriods] = useState(null);
    const [chosenDate, updateChosenDate] = useState("");
    const [chosenPeriods, updateChosenPeriods] = useState([]);
    const [chosenReason, updateChosenReason] = useState(null);
    const [noAbsenceReason, updateNoAbsenceReason] = useState("");
    const [isWorkshopOrTrip, updateIsWorkshopOrTrip] = useState(null);
    const [workshopOrTripComment, updateWorkshopOrTripComment] = useState("");
    const [reasonDetails, updateReasonDetails] = useState("");
    const [chosenNeedSubstitute, updateChosenNeedSubstitute] = useState(null);
    const [chosenText, updateChosenText] = useState("");
    const [chosenURL, updateChosenURL] = useState("");
    const [chosenFiles, updateChosenFiles] = useState([]);
    const [chosenOtherPeriod, updateChosenOtherPeriod] = useState(false);
    const [buttonDisabled, updateButtonDisabled] = useState(false);
    const [editable, updateEditable] = useState(true);
    const [substitutes, updateSubstitutes] = useState(null);
    const [substituteId, updateSubstituteId] = useState("");
    const [isTeacherAdmin, setisTeacherAdmin] = useState(false);
    const [absenceReasons, setAbsenceReasons] = useState([]);

    useEffect(() => {
        fetch("/js/" + props.lang + "/translation.js")
        .then(res => res.json())
        .then(res => {
            updateLang(res);
        });

        fetch("/" + props.lang + "/absences/" + props.teacherId + "/fetch/" + (props.entryId == null ? "new" : props.entryId))
        .then(res => res.json())
        .then(async res => {
            updateData(res);
            updateEditable(res.editable);

            if (res.substitutes != null) {
                updateSubstitutes(res.substitutes);
                setisTeacherAdmin(true);
            }

            if (isNaN(props.entryId) && props.entryId != "new") {
                updateChosenDate(props.entryId.substring(0, 10));
                updateChosenPeriods([parseInt(props.entryId.substring(11))]);
            }
            else if (res.entry != null) {
                updateSubstituteId(res.entry.substitute_id ?? "");
                updateChosenDate(res.entry.date);
                updateChosenPeriods([res.entry.period]);
                updateReasonDetails(res.entry.reason_details ?? "");
                updateChosenNeedSubstitute(res.entry.needs_substitute == 1);
                updateChosenText(res.entry.substitute_text ?? "");
                updateNoAbsenceReason(res.entry.no_substitute_reason ?? "");
                updateChosenURL(res.entry.substitute_url ?? "");
                updateWorkshopOrTripComment(res.entry.workshop_or_trip_comment ?? "");
                updateIsWorkshopOrTrip(res.entry.is_workshop_or_trip == 1);

                if (res.files != null && res.files.length > 0) {
                    let files = [];

                    res.files.forEach(async f => {
                        const response = await fetch(f.url);
                        const blob = await response.blob();
                        const file = new File([blob], f.name, {type: blob.type});
                        files.push(file);
                    })

                    updateChosenFiles(files);
                }
            }
        });
    }, [props.teacherId, props.lang, props.entryId]);

    useEffect(() => {
        if (chosenDate != null) {
            let day = new Date(chosenDate).getDay();

            if (day >= 0 && day <= 4) {
                fetch("/" + props.lang + "/absences/" + props.teacherId + "/fetch-reasons/" + chosenDate)
                .then(res => res.json())
                .then(reasonList => {
                    setAbsenceReasons(reasonList);

                    if (data.entry != null && data.entry.reason_id != null)  {
                        let tmpReason = null;

                        reasonList.forEach(r => {
                            if (r.id == data.entry.reason_id) {
                                tmpReason = r;
                            }
                        })

                        updateChosenReason(tmpReason);
                    }
                })

                fetch("/" + props.lang + "/absences/" + props.teacherId + "/fetch-periods/" + chosenDate)
                .then(res => res.json())
                .then(res => {
                    updatePeriods(res);
                });
            }
            else {
                setAbsenceReasons([]);
                updatePeriods(null);
            }
        }
    }, [chosenDate])

    const loadPeriods = evt => {
        if (evt.target.value != null && evt.target.value != "") {
            updateChosenDate(evt.target.value);
            updateChosenPeriods([]);
            updateChosenReason(null);
            updateChosenNeedSubstitute(null);
            updateChosenFiles([]);
            updateChosenOtherPeriod(false);
        }
        else {
            updatePeriods(null);
        }
    }

    const addFiles = files => {
        if (editable) {
            let fileList = [...chosenFiles];
            let errorFiles = [];
            let conflictFiles = [];

            for (let i = 0; i < files.length; i++) {
                if (files[i].name) {
                    let hasNameConflict = false;
                    let mustAdd = files[i].type.indexOf("application/pdf") !== -1 ||
                                files[i].type.indexOf("application/zip") !== -1 ||
                                files[i].type.indexOf("image/png") !== -1 ||
                                files[i].type.indexOf("image/jpeg") !== -1 ||
                                files[i].type.indexOf("image/bmp") !== -1 ||
                                files[i].type.indexOf("text/csv") !== -1 ||
                                files[i].type.indexOf("text/gif") !== -1 ||
                                files[i].type.indexOf("text/plain") !== -1 ||
                                files[i].type.indexOf("application/msword") !== -1 ||
                                files[i].type.indexOf("application/vnd.ms-excel") !== -1 ||
                                files[i].type.indexOf("application/vnd.ms-powerpoint") !== -1 ||
                                files[i].type.indexOf("application/vnd.openxmlformats-officedocument.presentationml.presentation") !== -1 ||
                                files[i].type.indexOf("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") !== -1 ||
                                files[i].type.indexOf("application/vnd.openxmlformats-officedocument.wordprocessingml.document") !== -1;

                    if (mustAdd) {
                        fileList.forEach(f => {
                            if (f.name === files[i].name) {
                                mustAdd = false;
                                hasNameConflict = true;
                            }
                        })
                    }

                    if (mustAdd) {
                        fileList.push(files[i]);
                    }
                    else {
                        if (!hasNameConflict) {
                            errorFiles.push(files[i].name);
                        }
                        else {
                            conflictFiles.push(files[i].name);
                        }
                    }
                }
            }

            if (errorFiles.length > 0 || conflictFiles.length > 0) {
                let txt = "";

                if (errorFiles.length > 0) txt += "<strong>" + errorFiles + "</strong> " + lang.gen["Has Invalid Format"] + ". ";
                if (conflictFiles.length > 0) txt += "<strong>" + conflictFiles + "</strong> " + lang.gen["Already Exists"] + ". ";

                show("Attention", txt, null);
            }

            updateChosenFiles(fileList);
        }
    }

    const selectPeriod = (evt, period) => {
        if (editable) {
            if (chosenPeriods.indexOf(period.no) === -1) {
                if (period.substitute_absence_id != null) {
                    updateChosenNeedSubstitute(false);
                }
                else if (period.absence_id == null || period.absence_id == props.entryId) {
                    updateChosenText("");
                    updateChosenURL("");
                    updateChosenFiles([]);
                }
                let tmpPeriods = [...chosenPeriods];

                if (isTeacherAdmin && props.entryId === "new") {
                    tmpPeriods.push(period.no);
                }
                else {
                    tmpPeriods = [period.no];
                }

                updateChosenPeriods(tmpPeriods);
            }
            else {
                let periods = [...chosenPeriods];
                periods.splice(chosenPeriods.indexOf(period.no), 1);
                updateChosenPeriods(periods);
            }

            if (Date.parse(chosenDate) <  new Date()) {
                updateChosenNeedSubstitute(false);
            }
        }
    }

    const forceNoSubstitute = () => {
        let mustForce = false;

        if (periods != null) {
            periods.forEach(p => {
                if (chosenPeriods.indexOf(p.no) >= 0 && p.substitute_absence_id != null) {
                    mustForce = true;
                }
            })
        }

        if (chosenPeriods.length == 1) {
            if (chosenPeriods[0] < 1) {
                mustForce = true;
            }
        }

        return mustForce;
    }

    const isPastAbsence = () => {
        let mustForce = false;

        if (periods != null) {
            periods.forEach(p => {
                if (chosenPeriods.indexOf(p.no) >= 0) {
                    mustForce = p.is_past;
                }
            })
        }

        return mustForce;
    }

    const updateReason = reason => {
        if (editable) {
            if (reason == null) {
                updateChosenReason(null)
            }
            else if (chosenReason === null) {
                updateChosenReason(reason);

                if (forceNoSubstitute()) {
                    updateChosenNeedSubstitute(false);
                }
                else if (reason.must_have_substitute) {
                    updateChosenNeedSubstitute(true);
                }
                else {
                    updateChosenNeedSubstitute(null);
                }

                if (reason.text != null) {
                    updateChosenText(reason.text);
                }
                else {
                    updateChosenText("");
                }

                if (!reason.need_more_info) {
                    updateReasonDetails("");
                }
            }
            else {
                updateChosenReason(null)
            }

            if (isPastAbsence()) {
                updateChosenNeedSubstitute(false);
            }
        }
    }

    const updateSubstituteChoice = val => {
        if (editable && (!isPastAbsence()) || (substitutes != null && substitutes.length > 0)) {
            if (forceNoSubstitute()) {
                updateChosenNeedSubstitute(false);
            }
            else if (chosenReason == null || !chosenReason.must_have_substitute) {
                if (chosenNeedSubstitute === null) {
                    updateChosenNeedSubstitute(val);
                }
                else {
                    updateChosenNeedSubstitute(null);
                }
            }
        }
    }

    const storeAbsence = periods => {
        if (periods.length > 0) {
            const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

            let formData = new FormData();
            formData.append("date", chosenDate);
            formData.append("period", periods[0]);
            formData.append("reason_id", chosenReason.id);
            formData.append("reason_details", reasonDetails ?? "");
            formData.append("needs_substitute", chosenNeedSubstitute ? "1" : "0");
            formData.append("is_workshop_or_trip", isWorkshopOrTrip ? "1" : "0");
            formData.append("workshop_or_trip_comment", workshopOrTripComment);

            if (substitutes != null && substitutes.length > 0) {
                formData.append("substitute_id", substituteId);
            }

            if (chosenNeedSubstitute) {
                formData.append("substitute_text", chosenText);
                formData.append("substitute_url", chosenURL);

                chosenFiles.forEach((f, idx) => {
                    formData.append("substitute_file_" + idx, f);
                })
            }
            else {
                formData.append("no_substitute_reason", noAbsenceReason);
            }

            fetch("/" + props.lang + "/absences/" + props.teacherId + "/" + props.entryId, {
                method : "POST",
                body : formData,
                headers: new Headers({
                    "X-CSRF-TOKEN": token,
                })
            })
            .then(res => res.json())
            .then(res => {
                periods.shift();

                if (periods.length === 0) {
                    if (isNaN(res)) {
                        show("Attention", res, null);
                        updateButtonDisabled(false);
                    }
                    else {
                        if (isTeacherAdmin) {
                            window.location.href = "/" + props.lang + "/admin/absences/";
                        }
                        else {
                            window.location.href = "/" + props.lang + "/absences/" + props.teacherId;
                        }
                    }
                }
                else {
                    storeAbsence(periods);
                }
            });
        }
    }

    const changeIsWorkshopOrTrip = val => {
        if (editable) {
            if (isWorkshopOrTrip === val) {
                updateIsWorkshopOrTrip(null);
                updateSubstituteChoice(null);
            }
            else {
                if (val) {
                    updateIsWorkshopOrTrip(true);
                    updateSubstituteChoice(true);
                }
                else {
                    updateIsWorkshopOrTrip(false);
                    updateSubstituteChoice(null);
                }
            }
        }
    }

    const confirmDeclaration = evt => {
        evt.preventDefault();

        if (chosenNeedSubstitute != null) {
            updateButtonDisabled(true);
            storeAbsence([...chosenPeriods]);
        }

        return false;
    }

    let isBtnDisabled = buttonDisabled ||
                        (chosenNeedSubstitute === false && !isTeacherAdmin && (noAbsenceReason == null || noAbsenceReason.trim().length == 0)) || !(chosenNeedSubstitute === false || (chosenText != null && chosenText.trim().length > 0)) ||
                        (!isTeacherAdmin && (chosenReason != null && chosenReason.need_more_info && (reasonDetails == null || reasonDetails.trim().length == 0))) ||
                        (isWorkshopOrTrip === true && (workshopOrTripComment == null || workshopOrTripComment.trim().length == 0));

    return <div className='container'>
                {
                    lang != null && data != null ?
                        <div>
                            <div className='row'>
                                <div className='col-sm-12 col-md-4 pt-1'>{lang.gen["Absence Date"]}</div>
                                <div className='col-sm-12 col-md-6'>
                                    <input type="date" disabled={!editable} min={data.dateMin} max={data.dateMax} className={'form-control ' + (chosenDate != "" ? "bg-success text-white" : "")} onChange={loadPeriods} value={chosenDate} />
                                </div>
                            </div>
                            {
                                periods != null ?
                                    <form onSubmit={e => confirmDeclaration(e)}>
                                        <div className='row pt-3'>
                                            <div className='col-sm-12 col-md-4 pt-3'>{lang.gen["Choose Period"]}</div>
                                            <div className='col-sm-12 col-md-6'>
                                                {
                                                    periods.map(p => {
                                                        let periodTitle = (p.no > -1 ? p.no + " - " : "") + " " + p.desc + " " + (p.substitute_absence_id != null ? " (" + lang.gen["In Absence Of"] + " " + p.substitute_name + ")" : "");

                                                        if (p.desc === lang.gen["Free"] && !chosenOtherPeriod && chosenPeriods.indexOf(p.no) === -1) {
                                                            return null
                                                        }
                                                        else {
                                                            return <div className={"card mt-2 user-select-none mb-2 p-2 " + (isTeacherAdmin && props.entryId === "new" ? "d-flex flex-row " : "") + (p.substitute_absence_id != null ? "substitute-bg-color " : "") +  (p.absence_id != null ? "bg-secondary" : "") + " " + (p.desc === lang.gen["Free"] ? "faded" : "") + " " + (chosenPeriods.indexOf(p.no) !== -1 ? "bg-success text-white" : (!isTeacherAdmin && chosenPeriods.length > 0 ? "d-none" : ""))} key={chosenDate + "-" + p.no + "-" + (p.substitute_absence_id != null ? "-sub" : "")} onClick={e => selectPeriod(e, p)}>
                                                                {
                                                                    isTeacherAdmin && props.entryId === "new" ?
                                                                        <span className='me-2'>
                                                                            <input type="checkbox" checked={chosenPeriods.indexOf(p.no) >= 0} readOnly={true} />
                                                                        </span>
                                                                    :
                                                                        null
                                                                }
                                                                <span>
                                                                    {periodTitle}
                                                                </span>
                                                            </div>
                                                        }
                                                    })
                                                }
                                                {
                                                    !chosenOtherPeriod && chosenPeriods.length === 0 ?
                                                            <div className={"card user-select-none mt-2 mb-2 p-2"} onClick={e => updateChosenOtherPeriod(true)}>
                                                                {lang.gen["Other"]}
                                                            </div>
                                                    :
                                                        null
                                                }
                                            </div>
                                        </div>

                                        {
                                            chosenPeriods.length > 0 ?
                                                <div>
                                                    <div className='row pt-3'>
                                                        <div className='col-sm-12 col-md-4 pt-3'>{lang.gen["Absence Reason"]}</div>
                                                        <div className='col-sm-12 col-md-6'>
                                                            {
                                                                absenceReasons.map(reason => {
                                                                    return <div key={reason.id} className={"card user-select-none mt-2 mb-2 p-2 " + (chosenReason == null ? "" : chosenReason.id == reason.id ? "bg-success text-white" : "d-none")} onClick={e => updateReason(reason)}>
                                                                        {reason.name}
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                                null
                                        }
                                        {
                                            chosenPeriods.length > 0 && chosenReason != null ?
                                                <div>
                                                    {
                                                        chosenReason.need_more_info ?
                                                            <div className='row pt-3'>
                                                                <div className='col-sm-12 col-md-4 pt-3'>{lang.gen["Need More Info Text"]}<br />{lang.gen["Required for Reason"]}</div>
                                                                <div className='col-sm-12 col-md-6'>
                                                                    <textarea className='form-control' required={!isTeacherAdmin} style={{minHeight : "100px"}} value={reasonDetails} onChange={e => updateReasonDetails(e.target.value)}></textarea>
                                                                </div>
                                                            </div>
                                                        :
                                                            null
                                                    }
                                                </div>
                                            :
                                                null
                                        }
                                        {
                                            chosenReason != null ?
                                                <div className='row pt-3'>
                                                    <div className='col-sm-12 col-md-4 pt-3' dangerouslySetInnerHTML={{__html: lang.gen["Is it a workshop or field trip"]}}></div>
                                                    <div className='col-sm-12 col-md-6'>
                                                        <div className={"card user-select-none mt-2 mb-2 p-2 " + (isWorkshopOrTrip == null ? "" : (isWorkshopOrTrip === false ? "bg-success text-white" : "d-none"))} onClick={e => changeIsWorkshopOrTrip(false)}>
                                                            {lang.gen["Answer No"]}
                                                        </div>
                                                        <div className={"card user-select-none mt-2 mb-2 p-2 " + (isWorkshopOrTrip == null ? "" : (isWorkshopOrTrip === true ? "bg-success text-white" : "d-none"))} onClick={e => changeIsWorkshopOrTrip(true)}>
                                                            {lang.gen["Answer Yes"]}
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                                null
                                        }
                                        {
                                            isWorkshopOrTrip === true ?
                                                <div>
                                                    <div className='row pt-3 pb-4'>
                                                        <div className='col-sm-12 col-md-4 pt-3'>{lang.gen["Please specify"]}<br/>({lang.gen["Required"]})</div>
                                                        <div className='col-sm-12 col-md-6'>
                                                            <textarea className='form-control' required={!isTeacherAdmin} style={{minHeight : "100px"}} value={workshopOrTripComment} onChange={e => updateWorkshopOrTripComment(e.target.value)}></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                                null
                                        }
                                        {
                                            isWorkshopOrTrip != null ?
                                                    <div className='row pt-3'>
                                                        <div className='col-sm-12 col-md-4 pt-3'>{lang.gen["Needs Substitute"]}</div>
                                                        <div className='col-sm-12 col-md-6'>
                                                            {
                                                                isWorkshopOrTrip === false ?
                                                                    <div className={"card user-select-none mt-2 mb-2 p-2 " + (chosenNeedSubstitute == null ? "" : (chosenNeedSubstitute === false ? "bg-success text-white" : "d-none"))} onClick={e => updateSubstituteChoice(false)}>
                                                                        {lang.gen["I Do Not Need A Substitute"]}
                                                                    </div>
                                                                :
                                                                    null
                                                            }
                                                            <div className={"card user-select-none mt-2 mb-2 p-2 " + (chosenNeedSubstitute == null ? "" : (chosenNeedSubstitute === true ? "bg-success text-white" : "d-none"))} onClick={e => {!isWorkshopOrTrip ? updateSubstituteChoice(true) : null}}>
                                                                {lang.gen["I Need A Substitute"]}
                                                            </div>
                                                        </div>
                                                    </div>
                                            :
                                                null
                                        }
                                        {
                                            chosenPeriods.length > 0 && isWorkshopOrTrip != null && chosenReason != null && (chosenNeedSubstitute === true || chosenNeedSubstitute === false) ?
                                                <div className=' pt-3'>
                                                    {
                                                        chosenNeedSubstitute ?
                                                            <div>
                                                                <div>
                                                                    {
                                                                        substitutes != null && !isNaN(props.entryId) ?
                                                                            <div className="row pb-2">
                                                                                <div className='col-sm-12 col-md-4 pt-1'>{lang.gen["Substitute"]}</div>
                                                                                <div className='col-sm-12 col-md-6'>
                                                                                    <select className='form-select' value={substituteId} onChange={e => updateSubstituteId(e.target.value)}>
                                                                                        <option value="">--</option>
                                                                                        {
                                                                                            substitutes.map(sub => {
                                                                                                return <option key={sub.id} value={sub.id}>
                                                                                                    {sub.name}
                                                                                                </option>
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        :
                                                                            null
                                                                    }

                                                                    <div className="row pb-2">
                                                                        <div className='col-sm-12 col-md-4 pt-1'>{lang.gen["Information To Give"]} <br />({lang.gen["Mandatory"]})</div>
                                                                        <div className='col-sm-12 col-md-6'>
                                                                            <textarea className='form-control' style={{minHeight : "200px"}} value={chosenText} onChange={e => updateChosenText(e.target.value)}></textarea>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row pb-2">
                                                                        <div className='col-sm-12 col-md-4 pt-1'>{lang.gen["PowerSchoolLearningUrl"]}</div>
                                                                        <div className='col-sm-12 col-md-6'>
                                                                            <input type="url" className='form-control' value={chosenURL} onChange={e => updateChosenURL(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row pb-3 d-none">
                                                                        <div className='col-sm-12 col-md-4 pt-1'>{lang.gen["Files To Attach"]}</div>
                                                                        <div className='col-sm-12 col-md-6'>
                                                                            {
                                                                                chosenFiles.map(file => {
                                                                                    return <div className='card mt-1 mb-1 p-1' key={file.name}>
                                                                                        <div>
                                                                                            <button className='btn btn-sm btn-danger me-2' onClick={e => updateChosenFiles(chosenFiles.filter(f => f.name != file.name))}>X</button>
                                                                                            <span className='d-inline-block'>
                                                                                                {file.name}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                })
                                                                            }
                                                                            <div className='card mt-1 mb-1 p-1'>
                                                                                <input className="form-control" multiple type="file" onChange={e => addFiles(e.target.files)} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        :
                                                            <div className="row pb-2">
                                                                <div className='col-sm-12 col-md-4 pt-1'>{lang.gen["No Substitute Reason"]} <br />({lang.gen["Mandatory"]})</div>
                                                                <div className='col-sm-12 col-md-6'>
                                                                    <textarea className='form-control' style={{minHeight : "200px"}} value={noAbsenceReason} onChange={e => updateNoAbsenceReason(e.target.value)}></textarea>
                                                                </div>
                                                            </div>
                                                    }
                                                    {
                                                        editable ?
                                                            <div className='mt-5 mb-5'>
                                                                <div className="row">
                                                                    <div className='col-sm-12 col-md-4 pt-1'></div>
                                                                    <div className='col-sm-12 col-md-6'>
                                                                        <button disabled={isBtnDisabled} className={'btn btn-primary ' + (!isBtnDisabled ? "" : "faded")} >{lang.gen["Complete Request"]}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        :
                                                            null
                                                    }
                                                </div>
                                            :
                                                null
                                        }
                                    </form>
                                :
                                    null
                            }
                        </div>
                    :
                        null
                }
          </div>
}